export const EmbedYoutubeResponseType = 'block_embed.youtube_video';

export type EmbedYoutubeInputs = {
  readonly url: string;
};

export type ResponseTypeForEmbedYoutube = {
  readonly template: typeof EmbedYoutubeResponseType;
  readonly blockData: {
    readonly url: string;
  };
};
