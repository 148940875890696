import { GalleryData } from '@shared/types';

export const GalleryResponseType = 'block_gallery.gallery';

export type GalleryInputs = { readonly gallery: GalleryData };

export type ResponseTypeForGallery = {
  readonly template: typeof GalleryResponseType;
  readonly blockData: GalleryData;
};
