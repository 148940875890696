export const EmbedJotformResponseType = 'block_embed.jotform';

export type EmbedJotformInputs = {
  readonly url: string;
};

export type ResponseTypeForEmbedJotform = {
  readonly template: typeof EmbedJotformResponseType;
  readonly blockData: {
    readonly url: string;
  };
};
