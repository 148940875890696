import { Signal } from '@angular/core';
import { BlockButton, BlockImage, BlockMediaPlace, CtaBlockBackgrondStyles, LinkButton, Media, MediaPosition, Theme } from '@shared/types';

export const LandingCtaWithMediaResponseType = 'block_landing_page.cta_with_background';

export type LandingCtaWithMediaInputs = {
  readonly title: string;
  readonly description: string;
  readonly videoUrl: string | undefined;
  readonly button: BlockButton;
  readonly image: BlockImage | undefined;
  readonly mediaPlace: Signal<BlockMediaPlace>;
  readonly backgroundColorStyle: CtaBlockBackgrondStyles;
};

export type ResponseContentTypeForLandingCtaWithMedia = {
  readonly template: typeof LandingCtaWithMediaResponseType;
  readonly title: string;
  readonly lead: string;
  readonly media: Media;
  readonly blockData: {
    readonly linkButton: LinkButton;
    readonly theme: Theme;
    readonly mediaPosition: MediaPosition;
  };
};
