export const RecommendedShopsCarouselResponseType = 'block_carousel.shops';

export type RecommendedShopsCarouselInputs = {
  readonly title: string;
  readonly description: string;
};

export type ResponseContentTypeForRecommendedShopsCarousel = {
  readonly template: typeof RecommendedShopsCarouselResponseType;
  readonly title: string;
  readonly lead: string;
};
