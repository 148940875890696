export const RecommendedProgramsCarouselResponseType = 'block_carousel.programs';

export type RecommendedProgramsCarouselInputs = {
  readonly title: string;
  readonly buttonText: string;
};

export type ResponseContentTypeForRecommendedProgramsCarousel = {
  readonly template: typeof RecommendedProgramsCarouselResponseType;
  readonly title: string;
};
