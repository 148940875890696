export const MenusCardsResponseType = 'block_group_generals.menu_section';

export type MenusCardsInputs = {
  readonly title: string;
  readonly url: string;
  readonly iconUrl: string;
};

export type ResponseTypeForMenusCards = {
  readonly template: typeof MenusCardsResponseType;
  readonly title: null;
  readonly content: null;
  readonly media: null;
  readonly medias: readonly [];
  readonly blockData: readonly ResponseContentDataTypeForMenusCards[];
  readonly position: number;
};

export type ResponseContentDataTypeForMenusCards = {
  readonly id: string;
  readonly title: string;
  readonly url: string;
  readonly icon: {
    readonly url: string;
  };
};
