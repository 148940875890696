export const FIXING_POSITION = 300;
export const NAV_CONTAINER_LEFT = 650;
export const IMAGE_LEFT = 174;

export const HomeBrandResponseType = 'block_homepage.brand';

export type HomeBrandInputs = {
  readonly title: string;
  readonly content: string;
  readonly brandId: string;
  readonly color: string;
  readonly imageUrl: string;
  readonly iconUrl: string;
};

export type ResponseContentTypeForHomeBrands = {
  readonly template: typeof HomeBrandResponseType;
  readonly title: string;
  readonly content: {
    readonly time: number;
    readonly blocks: readonly [
      {
        readonly id: string;
        readonly type: 'paragraph';
        readonly data: {
          readonly text: string;
        };
      },
    ];
    readonly version: string;
  };
  readonly media: null;
  readonly medias: readonly [];
  readonly blockData: {
    readonly brandId: string;
  };
  readonly position: number;
};
