export const EmbedTikTokResponseType = 'block_embed.tiktok';

export type EmbedTikTokInputs = {
  readonly url: string;
};

export type ResponseTypeForEmbedTikTok = {
  readonly template: typeof EmbedTikTokResponseType;
  readonly blockData: {
    readonly url: string;
  };
};
