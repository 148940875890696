export const HomeHeroResponseType = 'block_homepage.homepage_video';

export type HomeHeroInputs = {
  readonly title: string;
  readonly videoUrl: string;
};

export type ResponseContentTypeForHomeHero = {
  readonly template: typeof HomeHeroResponseType;
  readonly title: string;
  readonly content: null;
  readonly media: {
    readonly url: string;
  };
  readonly medias: readonly [];
  readonly blockData: readonly [];
  readonly position: number;
};
