import { BlockButton, BlockImage, DefaultContent, LinkButton, Media, MediaPosition, Theme } from '@shared/types';

export const LandingHeroResponseType = 'block_landing_page.hero';

export type LandingHeroInputs = {
  readonly title: string;
  readonly descriptionBlocks: ReadonlyArray<DefaultContent>;
  readonly videoUrl: string | undefined;
  readonly button: BlockButton;
  readonly image: BlockImage | undefined;
};

export type ResponseContentTypeForLandingHero = {
  readonly template: typeof LandingHeroResponseType;
  readonly title: string;
  readonly media: Media;
  readonly content: { readonly blocks: ReadonlyArray<DefaultContent> };
  readonly blockData: {
    readonly linkButton: LinkButton;
    readonly theme: Theme;
    readonly mediaPosition: MediaPosition;
  };
};
