import { LinkButton, MediaPosition, Theme } from '@shared/types';

export const LandingButtonResponseType = 'block_group_contents.link_button';

export type LandingButtonInputs = {
  readonly title: string;
  readonly slug: string;
  readonly theme: 'primary' | 'secondary' | 'tertiary';
  readonly scheme: 'dark' | 'gold' | 'white';
  readonly back?: boolean;
  readonly icon?: string;
};

export type ResponseContentTypeForLandingButton = {
  readonly template: typeof LandingButtonResponseType;
  readonly title: string;
  readonly blockData: {
    readonly linkButton: LinkButton;
    readonly theme: Theme;
    readonly mediaPosition: MediaPosition;
  };
};
