export const EmbedFacebookVideoResponseType = 'block_embed.facebook_video';

export type EmbedFacebookVideoInputs = {
  readonly url: string;
};

export type ResponseTypeForEmbedFacebookVideo = {
  readonly template: typeof EmbedFacebookVideoResponseType;
  readonly blockData: {
    readonly url: string;
  };
};
