export const HomeProgramCarouselResponseType = 'block_homepage.programs_slider';

export type HomeProgramCarouselInputs = {
  readonly url: string;
  readonly title: string;
  readonly imageUrl: string;
  readonly brandId: string;
};

export type ResponseContentTypeForHomeProgramCarousel = {
  readonly template: typeof HomeProgramCarouselResponseType;
  readonly title: null;
  readonly content: null;
  readonly media: null;
  readonly medias: readonly [];
  readonly blockData: readonly ResponseContentDataTypeForHomeProgramCarousel[];
  readonly position: number;
};

export type ResponseContentDataTypeForHomeProgramCarousel = {
  readonly id: string;
  readonly title: string;
  readonly slug: string;
  readonly bannerMedia: {
    readonly url: string;
  };
  readonly brand: string;
};
