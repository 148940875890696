import { BlockButton, BlockImage } from '@shared/types';

export const LandingMapResponseType = 'landingpage_map';

export type LandingMapInputs = {
  readonly title: string;
  readonly description: string;
  readonly button: BlockButton;
  readonly image: BlockImage;
};

export type ResponseContentTypeForLandingMap = {
  readonly template: typeof LandingMapResponseType;
  readonly title: string;
  readonly description: string;
  readonly media: {
    readonly image: {
      readonly url: string;
      readonly alt: string;
    };
  };
  readonly buttonSlug: {
    readonly title: string;
    readonly url: string;
  };
};
