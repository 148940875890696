import { DefaultContent } from '@shared/types';

export const DefaultResponseType = 'block_group_generals.default';

export type DefaultInputs = {
  readonly blocks: readonly DefaultContent[];
};

export type ResponseTypeForDefault = {
  readonly template: typeof DefaultResponseType;
  readonly title?: string;
  readonly content: {
    readonly time: number;
    readonly blocks: readonly DefaultContent[];
  };
};
