import { Signal } from '@angular/core';
import { BlockMediaPlace, MediaPosition, Theme } from '@shared/types';

export const LandingJotFormResponseType = 'block_landing_page.jotform';

export type LandingJotFormInputs = {
  readonly title: string;
  readonly description: string;
  readonly jotId: string;
  readonly formHeight: number;
  readonly formPlace: Signal<BlockMediaPlace>;
  readonly backgroundColor: string;
  readonly textBGColorStyle: 'dark' | 'light';
};

export type ResponseContentTypeForLandingJotForm = {
  readonly template: typeof LandingJotFormResponseType;
  readonly title: string;
  readonly lead: string;
  readonly blockData: {
    readonly jotformId: string;
    readonly textTheme: Theme;
    readonly formHeight: number;
    readonly mediaPosition: MediaPosition;
    readonly backgroundColor: string;
  };
};
