import { BlockButton, BlockButtonShemeStyles, BlockImage, DefaultContent, LinkButton, TextColorStyles } from '@shared/types';

export const LandingSightBrandResponseType = 'block_landing_page.brand';

export type LandingSightBrandInputs = {
  readonly title: string;
  readonly descriptionBlocks: ReadonlyArray<DefaultContent>;
  readonly button: BlockButton;
  readonly image: BlockImage;
  readonly logoUrl: string;
  readonly backgroundColor: string;
  readonly textColorStyle: TextColorStyles;
  readonly buttonColorStyle: BlockButtonShemeStyles;
};

export type ResponseContentTypeForLandingSightBrand = {
  readonly template: typeof LandingSightBrandResponseType;
  readonly title: string;
  readonly content: { readonly blocks: ReadonlyArray<DefaultContent> };

  readonly blockData: {
    readonly brandId: string;
    readonly linkButton: LinkButton;
  };
};
