export const EmbedFacebookPostResponseType = 'block_embed.facebook_post';

export type EmbedFacebookPostInputs = {
  readonly url: string;
};

export type ResponseTypeForEmbedFacebookPost = {
  readonly template: typeof EmbedFacebookPostResponseType;
  readonly blockData: {
    readonly url: string;
  };
};
