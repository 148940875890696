import { Signal } from '@angular/core';
import { BlockButton, BlockImage, BlockMediaPlace, DefaultContent, LinkButton, Media, MediaPosition } from '@shared/types';

export const LandingZigzagResponseType = 'block_landing_page.zig-zag';

export type LandingZigzagInputs = {
  readonly title: string;
  readonly description: ReadonlyArray<DefaultContent>;
  readonly button: BlockButton;
  readonly image: BlockImage;
  readonly mediaPlace: Signal<BlockMediaPlace>;
};

export type ResponseContentTypeForLandingZigzag = {
  readonly template: typeof LandingZigzagResponseType;
  readonly title: string;
  readonly content: { readonly blocks: ReadonlyArray<DefaultContent> };
  readonly media: Media;
  readonly blockData: {
    readonly linkButton: LinkButton;
    readonly mediaPosition: MediaPosition;
  };
};
