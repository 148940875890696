import { BlockButton } from '@shared/types';

export const RecommendedSightsCarouselResponseType = 'block_carousel.sights';

export type RecommendedSightsCarouselInputs = {
  readonly title: string;
  readonly description: string;
  readonly button: BlockButton;
};

export type ResponseContentTypeForRecommendedSightsCarousel = {
  readonly template: typeof RecommendedSightsCarouselResponseType;
  readonly title: string | null;
  readonly lead: string | null;
  readonly blockData: {
    readonly linkButton: {
      readonly href: string | null;
      readonly type: 'default';
      readonly title: string;
    };
  } | null;
};
