export const EmbedYoutubeShortResponseType = 'embed';

export type EmbedYoutubeShortInputs = {
  readonly url: string;
};

export type ResponseTypeForEmbedYoutubeShort = {
  readonly template: typeof EmbedYoutubeShortResponseType;
  readonly blockData: {
    readonly url: string;
  };
};
