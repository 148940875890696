import { LinkButton } from '@shared/types';

export const LandingCtaResponseType = 'block_landing_page.cta';

export type LandingCtaInputs = {
  readonly title: string;
  readonly description: string;
  readonly buttonText: string;
  readonly slug: string;
};

export type ResponseContentTypeForLandingCta = {
  readonly template: typeof LandingCtaResponseType;
  readonly title: string;
  readonly lead: string;
  readonly blockData: {
    readonly linkButton: LinkButton;
  };
};
