import { TextBlockBackgrondStyles, Theme } from '@shared/types';

export const LandingTextResponseType = 'block_group_generals.text_with_background';

export type LandingTextInputs = {
  readonly title: string;
  readonly description: string;
  readonly backgroundColorStyle: TextBlockBackgrondStyles;
};

export type ResponseContentTypeForLandingText = {
  readonly template: typeof LandingTextResponseType;
  readonly title: string;
  readonly lead: string;
  readonly blockData: {
    readonly theme: Theme;
  };
};
