export const LandingKpiResponseType = 'block_landing_page.kpi';

export type LandingKpiInputs = {
  readonly leads: {
    readonly title: string;
    readonly description: string;
  }[];
};

export type ResponseContentTypeForLandingKpi = {
  readonly template: typeof LandingKpiResponseType;
  readonly blockData: {
    readonly title: string;
    readonly lead: string;
  }[];
};
