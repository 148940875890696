export const LandingTitleResponseType = 'block_group_contents.title';

export type LandingTitleInputs = {
  readonly title: string;
  readonly heading: 'h1' | 'h2' | 'h3' | 'h4';
};

export type ResponseContentTypeForLandingTitle = {
  readonly template: typeof LandingTitleResponseType;
  readonly title: string;
  readonly heading: 'h1' | 'h2' | 'h3' | 'h4';
};
