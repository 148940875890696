export const RecommendedNewsCarouselResponseType = 'block_carousel.news';

export type RecommendedNewsCarouselInputs = {
  readonly title: string;
  readonly description: string;
};

export type ResponseContentTypeForRecommendedNewsCarousel = {
  readonly template: typeof RecommendedNewsCarouselResponseType;
  readonly title: string;
  readonly lead: string;
};
